import React from 'react';
import styled from 'styled-components';
import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import { menuSelector } from '@szhsin/react-menu/style-utils';
import { Link } from 'gatsby';
import CustomIcon from './CustomIcon';
import { Strong, P } from './Typography';

const DropdownLink = styled(Link)`
  text-decoration: none;
  text-transform: none;
  display: flex;

  &:hover {
    color: #2cd19e;
  }
`;
const StyledMenu = styled(Menu)<{ withoutIcon?: boolean }>`
  ${menuSelector.name} {
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 1rem;
    min-width: 20rem;
    flex-wrap: wrap;
    min-height: 2rem;
  }
`;
const Texts = styled.div`
  margin-left: 1rem;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const ItemTitle = styled(Strong)<{ label: string }>`
  color: #13273f;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: ${(props) =>
    props.label.toLowerCase().includes(`ebooks`) ? `none` : `capitalize`};
  letter-spacing: 0.78px;
`;
const ItemSubTitle = styled(P)`
  color: #495b6c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.78px;
`;
const StyledMenuButton = styled(MenuButton)`
  font-family: 'montserrat';
  background: none;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  transition: 200ms;
  color: black;
  padding: 0;
  cursor: pointer;
  &:hover {
    color: #2cd19e;
  }
`;

const StyledMenuItem = styled(MenuItem)<{ withoutIcon?: boolean }>`
  cursor: pointer;
  div > span {
    border-radius: 50%;
    background-color: #13273f;
    transition: 0.2s;
  }
  div > span > img {
    background-color: #13273f;
    transition: 0.2s;
  }
  ${({ withoutIcon }) => withoutIcon && `padding: 10px 24px 10px 0;`}
  &:hover {
    div > span {
      background-color: #2cd19e;
    }
    div > span > img {
      background-color: #2cd19e;
    }
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ComingSoon = styled(P)`
  color: #ef6355;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;
interface DropdownProps {
  buttonText: string;
  dropdownLinks: {
    label: string;
    description?: string;
    link?: string;
    icon?: string;
    comingSoon?: boolean;
  }[];
}

const DropdownMenu: React.FunctionComponent<DropdownProps> = ({
  buttonText,
  dropdownLinks,
}) => (
  <div>
    <StyledMenu
      transition
      menuButton={<StyledMenuButton>{buttonText}</StyledMenuButton>}
    >
      {dropdownLinks.map(({ label, description, link, icon, comingSoon }) => (
        <StyledMenuItem key={label} withoutIcon={!icon}>
          <DropdownLink to={link}>
            {!!icon && <CustomIcon src={icon} small={!description} />}
            <Texts>
              <Row>
                <ItemTitle label={label}>{label}</ItemTitle>
                {comingSoon && <ComingSoon>COMING SOON!</ComingSoon>}
              </Row>
              {description && <ItemSubTitle>{description}</ItemSubTitle>}
            </Texts>
          </DropdownLink>
        </StyledMenuItem>
      ))}
    </StyledMenu>
  </div>
);

export default DropdownMenu;
